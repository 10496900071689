import { Error } from 'components/Error/Error'
import { PublicLayout } from 'layout/PublicLayout'
import Head from 'next/head'
export default function Custom404() {
  return (
    <>
      <Head>
        {/* Gtag */}

        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-5LS3FRLR'
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-5LS3FRLR');
            `,
          }}
        />
        <title>JusplayToys - 404</title>
      </Head>
      <PublicLayout breadcrumbTitle='404 Page' description='Oops!'>
        <Error />
      </PublicLayout>
    </>
  )
}
